import { APIFilter } from '@/utils/api'

export default {
  async selectTecnico (Vue) {
    const apiFilter = new APIFilter()
    apiFilter.addGT('estado', 0)
    const resp = await Vue.$api.call('tecnico.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
