<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <orden-trabajo-asignar-form
          :id="routeParams.idparte_trabajo"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import OrdenTrabajoAsignarForm from './components/OrdenTrabajoAsignarForm'
import Data from './OrdenTrabajoAsignarData'
import { get } from 'vuex-pathify'

export default {
  components: { OrdenTrabajoAsignarForm },
  mixins: [formPageMixin],
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      const ordenTrabajo = await Data.selectOrdenTrabajo(this, this.routeParams.idorden_trabajo)
      this.title = `Asignar orden Nº${ordenTrabajo.orden_trabajo_serie_numero}`
    },
    async submitForm () {
      const parteAsignado = await this.$online.ordenTrabajo.asignar(this.routeParams.idorden_trabajo, this.formData.idtecnico_principal)
      for (const tecnicoAdicional of this.formData.idtecnicos_adicionales) {
        await this.$online.parteTrabajoTecnico.insertParteTrabajoTecnico(this, parteAsignado.idparte_trabajo, tecnicoAdicional)
      }
      await this.$dirty.modified(this.$dirty.ENTITIES.remote.ordenTrabajo, this.routeParams.idorden_trabajo)
      this.$alert.showSnackbarSuccess(`Se ha creado el parte de trabajo Nº${parteAsignado.idparte_trabajo}`)
      this.$appRouter.go(-1)
    },
  },
}
</script>
