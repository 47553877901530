<template>
  <b10-base>
    <b10-autocomplete
      v-model="form.idtecnico_principal"
      :items="tecnicos"
      item-value="idtecnico"
      item-text="nombre_y_apellido"
      label="Técnico principal"
      :rules="formRules.idtecnico_principal"
      clearable
      @change="changeTecnicoPrincipal"
    />
    <b10-autocomplete
      v-model="form.idtecnicos_adicionales"
      :items="tecnicosNoPrincipal"
      item-value="idtecnico"
      item-text="nombre_y_apellido"
      label="Técnicos adicionales"
      clearable
      multiple
      chips
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './OrdenTrabajoAsignarFormData'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        idtecnico_principal: null,
        idtecnicos_adicionales: [],
      },
      formRules: {
        idtecnico_principal: [
          v => !!v || 'Campo requerido'
        ],
      },
      tecnicos: [],
    }
  },
  computed: {
    tecnicosNoPrincipal () {
      return _.filter(this.tecnicos, item => item.idtecnico !== this.form.idtecnico_principal)
    }
  },
  async created () {
    const [dataset] = await Data.selectTecnico(this)
    this.tecnicos = dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    changeTecnicoPrincipal () {
      if (this.form.idtecnico_principal) {
        this.form.idtecnicos_adicionales.splice(this.form.idtecnicos_adicionales.indexOf(this.form.idtecnico_principal), 1)
      }
    },
  },
}
</script>
