import { APIFilter } from '@/utils/api'

export default {
  async selectOrdenTrabajo (Vue, idordenTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idorden_trabajo', idordenTrabajo)
    return (
      await Vue.$api.call('ordenTrabajo.select', { filter: apiFilter })
    ).data.result.dataset[0]
  },
}
